.container {

  .invest {
    &__account {
      font-size: 0.8rem;
      color:#f6d34a;
      position:absolute;
      top: 20px;
      right: 20px;
    }

    &__header {
      h1 {
        font-size: 6em;

        @media screen and (max-width: 500px) {
          font-size:3rem;
        }

        em {
          color:#f6d34a;
          font-style: normal;
          font-size: 2rem;
          display: block;
          float:right;
          margin-left: 30px;
        }
      }
    }
  }
}
