.box {
  justify-content: center;
  text-align: center;

  .wrapper {
    @media screen and (max-width: 500px) {
      clear:both;
      display: block;
    }

    label {
      display: block;
      margin-bottom: 30px;
    }

    .input-eth {
      input {
        text-align: center;
        font-size: 1.8rem;
      }
    }
    .conversion {
      margin-top: 15px;
      color:#acacac;
      font-size: 0.9rem;

      strong {
        letter-spacing: 2px;
      }
    }

    .action-button {
      margin-top:20px;
      margin-bottom: 30px;
      width: 200px;
      .txn-loading {
        color: #111;
      }
    }
  }

  &__footer {
    @media screen and (max-width: 500px) {
      clear:both;
    }

    .float-button {
      position: fixed;
      bottom: 20px;
      right: 20px;
      border-radius: 5px;
      background: #777;
      padding: 10px 18px;
      line-height: 1rem;
      height:auto;

      @media screen and (max-width: 500px) {
        position:relative;
        bottom:auto;
        right:auto;
        margin-top:50px;
      }
    }
    .float-button:hover {
      background-color:#aaa
    }
  }
}
